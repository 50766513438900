<template>
  <vue-good-table
    class="position-relative"
    mode="remote"
    :columns="columns"
    responsive
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: false,
    }"
    :pagination-options="{
      enabled: false,
    }"
    style-class="vgt-table striped"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <span>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

export default {
  name: 'ReportsOverview',
  components: {
    VueGoodTable,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  data() {
    return {
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
    };
  }, 
  methods: {

  }
};
</script>
<style lang="scss" scoped>
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>
<template>
  <section>
    <b-tabs
      nav-class="d-flex justify-content-between"
      nav-item-class="flex-fill"
      content-class="tab-custom"
      pills
      card
    >
      <executive-summary 
        v-if="defaultProgram && defaultProgram.executive_summary"
        :reportSegments="reportSegments"
      />
      <member-engagement 
        v-if="defaultProgram && defaultProgram.member_engagement"
        :reportSegments="reportSegments"
      />
      <survey-insights
        v-if="defaultProgram && defaultProgram.survey_insights" 
        :reportSegments="reportSegments"
      />
    </b-tabs>
    <!-- <b-card title="Engagement & Activity this month">
      <b-row>
        <b-col sm="8">
          <div class="d-flex align-items-center ml-50">
            <b-avatar
              size="48"
              variant="light-primary"
              class="mr-2"
            >
              <feather-icon icon="BarChartIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalSessions }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Sessions
              </p>
            </div>
            <b-avatar
              size="48"
              variant="light-success"
              class="mr-2 ml-3"
            >
              <feather-icon icon="LayersIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalLearningModules }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Module Engagements
              </p>
            </div>
            <b-avatar
              size="48"
              variant="light-success"
              class="mr-2 ml-3"
            >
              <feather-icon icon="DownloadIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalDownloads }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Resources
              </p>
            </div>
            <b-avatar
              v-if="!isProgramTraining"
              size="48"
              variant="light-secondary"
              class="mr-2 ml-3"
            >
              <feather-icon icon="SendIcon" />
            </b-avatar>
            <div v-if="!isProgramTraining" class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalComms }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Communications
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="d-flex justify-content-end">
            <b-form-group 
              v-if="!isProgramTraining"
              label="Select Groups to filter by" 
              label-for="groups"
              class="mr-1 mb-0"
            >
              <aom-group-filter
                v-model="selectedGroup"
                :program-id="$route.params.id"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <daily-logins-report
      :group-id="selectedGroup"
      @count="updateSessionsCount"
    />
    <daily-learning-report
      v-if="isProgramTraining"
      :group-id="selectedGroup"
      @count="updateLearningModules"
    />
    <daily-downloads-report
      :group-id="selectedGroup"
      @count="updateDownloadsCount"
    />
    <daily-comms-report 
      v-if="!isProgramTraining"
      :group-id="selectedGroup"
      @count="updateCommsCount"
    /> -->
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import KeyResults from './KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import ExecutiveSummary from './ExecutiveSummary.vue';
import MemberEngagement from './MemberEngagement.vue';
import SurveyInsights from './SurveyInsights.vue';
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";

export default {
  name: 'ReportsOverview',
  components: {
    SurveyInsights,
    ExecutiveSummary,
    MemberEngagement,
    VueGoodTable,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  data() {
    return {
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
      optionsParticipantOverview: {
        chart: {
          height: 400,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function(val) {
                  return parseInt(val) + "% active";
                },
              },
            }
          }
        },

      },
      reportSegments: []
    };
  }, 
  methods: {
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  },
  async created() {
    this.selectedGroup = this.$route.query.group_id;
    const response = await reportService.getReportSegments(this.$route.params.id);
    this.reportSegments = response.data.items;
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>
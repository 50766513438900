<template>
  <b-tab
    title-item-class="flex-fill"
    lazy
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Executive Summary</span>
    </template>
    <b-card>
      <h4>Executive summary</h4>
    </b-card>
    <div 
      v-for="(segment, index) in executiveSegments" 
      :key="index"
    >
      <b-card class="mt-2" v-if="segment.name== 'Program Key Dates'">
        <h4>Program key dates</h4>
        <b-row>
          <b-col>
            <p>----------</p>
            <p>20/08/24</p>
            <p>Member applications open</p>
          </b-col>
          <b-col>
            <p>----------</p>
            <p>20/08/24</p>
            <p>Member applications open</p>
          </b-col>
          <b-col>
            <p>----------</p>
            <p>20/08/24</p>
            <p>Member applications open</p>
          </b-col>
          <b-col>
            <p>----------</p>
            <p>20/08/24</p>
            <p>Member applications open</p>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mt-2" v-if="segment.name== 'Executive Summary'">
        <b-row class="d-flex">
          <b-col>
            <h4>Executive summary </h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  v-if="segment.summary == null"
                  @click="showSummaryTextarea(segment)"
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
                <action-dropdown-item
                  v-if="segment.summary != null"
                  @click="showSummaryTextarea(segment)"
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Edit text</span>
                </action-dropdown-item>
                <action-dropdown-item
                  v-if="segment.summary != null"
                  @click="deleteSegmentSummary(segment)"
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Delete text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <div>
          The retention rate for participants remained strong throughout the program, there were a few drop outs towards the end of the program
          The retention rate for participants remained strong throughout the program, there were a few drop outs towards the end of the program
          The retention rate for participants remained strong throughout the program, there were a few drop outs towards the end of the program
          The retention rate for participants remained strong throughout the program, there were a few drop outs towards the end of the program
        </div>

        <p class="mt-2" v-if="segment.summary != null && currentEditSummarySegmentId == null">
          {{ segment.summary }}
        </p>
        <b-form-textarea v-if="currentEditSummarySegmentId == segment.id" v-model="segment.summary" @blur="updateSegmentSummary(segment)" />
      </b-card>
      <b-card class="mt-2" v-if="segment.name== 'Program Success Metrics'">
        <b-row class="d-flex">
          <b-col>
            <h4>Program success metrics </h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <p>
              <apexchart
                height="100"
                type="radialBar"
                :options="options"
                :series="[70]"
              />
            </p>
            <h1>100%</h1>
            <h4>Number of pairs matched</h4>
            <p @dblclick="showProgramGraphSummary('NumberOfPairs')">
              <b-form-textarea 
                v-if="currentGraphEdit == 'NumberOfPairs'" 
                v-model="graphSummary['NumberOfPairs'].summary"
                @blur="updateProgramGraphSummary('NumberOfPairs')"
              />
              <span v-else>{{ getProgramGraphSummary('NumberOfPairs') }}</span>
            </p>
          </b-col>
          <b-col class="text-center">
            <p>
              <apexchart
                height="100"
                type="radialBar"
                :options="options"
                :series="[70]"
              />
            </p>
            <h1>100%</h1>
            <h4>Resource & process satisfaction</h4>
            <p @dblclick="showProgramGraphSummary('ResourceAndProcessSupport')">
              <b-form-textarea 
                v-if="currentGraphEdit == 'ResourceAndProcessSupport'" 
                v-model="graphSummary['ResourceAndProcessSupport'].summary"
                @blur="updateProgramGraphSummary('ResourceAndProcessSupport')"
              />
              <span v-else>{{ getProgramGraphSummary('ResourceAndProcessSupport') }}</span>
            </p>
          </b-col>
          <b-col class="text-center">
            <p>
              <apexchart
                height="100"
                type="radialBar"
                :options="options"
                :series="[70]"
              />
            </p>
            <h1>100%</h1>
            <h4>Match satisfaction</h4>
            <p @dblclick="showProgramGraphSummary('MatchSatisfaction')">
              <b-form-textarea 
                v-if="currentGraphEdit == 'MatchSatisfaction'" 
                v-model="graphSummary['MatchSatisfaction'].summary"
                @blur="updateProgramGraphSummary('MatchSatisfaction')"
              />
              <span v-else>{{ getProgramGraphSummary('MatchSatisfaction') }}</span>
            </p>
          </b-col>
          <b-col class="text-center">
            <p>
              <apexchart
                height="100"
                type="radialBar"
                :options="options"
                :series="[70]"
              />
            </p>
            <h1>100%</h1>
            <h4>Match retention</h4>
            <p @dblclick="showProgramGraphSummary('MatchRetention')">
              <b-form-textarea 
                v-if="currentGraphEdit == 'MatchRetention'" 
                v-model="graphSummary['MatchRetention'].summary"
                @blur="updateProgramGraphSummary('MatchRetention')"
              />
              <span v-else>{{ getProgramGraphSummary('MatchRetention') }}</span>
            </p>
          </b-col>
          <b-col class="text-center">
            <p>
              <apexchart
                height="100"
                type="radialBar"
                :options="options"
                :series="[70]"
              />
            </p>
            <h1>100%</h1>
            <h4>Match meeting cadence</h4>
            <p @dblclick="showProgramGraphSummary('MatchMeetingCadence')">
              <b-form-textarea 
                v-if="currentGraphEdit == 'MatchMeetingCadence'" 
                v-model="graphSummary['MatchMeetingCadence'].summary"
                @blur="updateProgramGraphSummary('MatchMeetingCadence')"
              />
              <span v-else>{{ getProgramGraphSummary('MatchMeetingCadence') }}</span>
            </p>
          </b-col>
        </b-row>
      </b-card>
      <b-row v-if="segment.name== 'Program Sessions'">
        <b-col>
          <b-card>
            <h4>Participant overview </h4>
            <b-row class="mt-2">
              <b-col>
                <h1>164</h1>
                <p>Active participants</p>

                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-primary"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Mentors
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-info"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Mentees
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-warning"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Both roles
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
              </b-col>
              <b-col>
                <apexchart
                  type="radialBar"
                  :options="optionsParticipantOverview"
                  :series="[70]"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <h4>Training progress </h4>
            <b-row class="mt-2">
              <b-col class="mt-4">
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-primary"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Mentors
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-info"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Mentees
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <b-avatar
                    size="50"
                    variant="light-warning"
                    class="mr-2"
                    style="border-radius: 5px !important ;"
                  >
                    <feather-icon
                      size="20"
                      icon="UsersIcon"
                    />
                  </b-avatar>
                  <div class="d-flex flex-column">
                    <p class="card-text font-small-3 mb-0">
                      Both roles
                    </p>
                    <h1 class="font-weight-bolder mb-0 active-programs">
                      11
                    </h1>
                  </div>
                </div>
              </b-col>
              <b-col>
                <apexchart
                  type="radialBar"
                  :options="optionsParticipantOverview"
                  :series="[70]"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-card class="mt-1" v-if="segment.name== 'Match summary'">
        <h4>Match summary</h4>
        <b-row class="mt-2">
          <b-col class="text-center">
            <h1>74</h1>
            <p>Active</p>
          </b-col>
          <b-col class="text-center">
            <h1>74</h1>
            <p>Active</p>
          </b-col>
          <b-col class="text-center">
            <h1>74</h1>
            <p>Active</p>
          </b-col>
          <b-col class="text-center">
            <h1>74</h1>
            <p>Active</p>
          </b-col>
        </b-row>
      </b-card>
      
      <b-row v-if="segment.name== 'Mentors & Mentees'">
        <b-col>
          <b-card tag="article">
            <b-col class="p-0">
              <b-card-title class="text-truncate">
                Mentors
              </b-card-title>
              <div class="d-flex mb-2 mt-2">
                <b-col>
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Matched
                      </h6>
                      <p class="font-small-3 m-0">
                        10
                      </p>
                    </div>
                  </b-row>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Unmatched
                      </h6>
                      <p class="font-small-3 m-0">
                        20
                      </p>
                    </div>
                  </b-row>
                </b-col>
              </div>

              <div class="mb-2">
                <div class="w-100 d-flex justify-content-between">
                  <p class="font-weight-bold m-0">
                    {{ `10 matched out of 17` }}
                  </p>
                  <p class="m-0">
                    80%
                  </p>
                </div>
                <b-progress
                  :value="80"
                  class="w-100"
                  :class="
                    80 > 50 ?
                      'default-green-chart-color' :
                      'default-chart-color'
                  "
                />
              </div>
            </b-col>
          </b-card>
        </b-col>
        <b-col>
          <b-card tag="article">
            <b-col class="p-0">
              <b-card-title class="text-truncate">
                Mentees
              </b-card-title>
              <div class="d-flex mb-2 mt-2">
                <b-col>
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Matched
                      </h6>
                      <p class="font-small-3 m-0">
                        10
                      </p>
                    </div>
                  </b-row>
                </b-col>
                <b-col class="d-flex justify-content-end">
                  <b-row align-v="center">
                    <b-avatar
                      square
                      size="40"
                      variant="light-success"
                      class="mr-1"
                    >
                      <feather-icon
                        icon="UserIcon"
                        size="25"
                      />
                    </b-avatar>
                    <div>
                      <h6 class="font-weight-bolder m-0">
                        Unmatched
                      </h6>
                      <p class="font-small-3 m-0">
                        20
                      </p>
                    </div>
                  </b-row>
                </b-col>
              </div>

              <div class="mb-2">
                <div class="w-100 d-flex justify-content-between">
                  <p class="font-weight-bold m-0">
                    {{ `10 matched out of 17` }}
                  </p>
                  <p class="m-0">
                    80%
                  </p>
                </div>
                <b-progress
                  :value="80"
                  class="w-100"
                  :class="
                    80 > 50 ?
                      'default-green-chart-color' :
                      'default-chart-color'
                  "
                />
              </div>
            </b-col>
          </b-card>
        </b-col>
      </b-row>
      <b-card v-if="segment.name== 'Key Results'">
        <b-row class="d-flex">
          <b-col>
            <h4>Key results</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <key-results/>
      </b-card>
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import KeyResults from './KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle,
  BButton, BNavItemDropdown,
  BFormTextarea,
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import { isDisplaySegment } from "@/@aom-core/utils/utils";
import { makeSuccessToast } from "@/libs/utils";

export default {
  components: {
    ActionDropdownItem,
    VueGoodTable,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation,
    BButton, BNavItemDropdown,
    BFormTextarea
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    executiveSegments() {
      return this.reportSegments.filter(segment => segment.section == 'executive_summary');
    }
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data() {
    return {
      graphSummary: [],
      currentGraphEdit: null,
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
      optionsParticipantOverview: {
        chart: {
          height: 400,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function(val) {
                  return parseInt(val) + "% active";
                },
              },
            }
          }
        },

      },
      currentEditSummarySegmentId: null
    };
  }, 
  async created() {
    const programSuccess = this.reportSegments.find(segment => segment.name == 'Program Success Metrics');
    if (programSuccess) {
      this.graphSummary = programSuccess.data;
    }
  },
  methods: {
    showSummaryTextarea(segment) {
      this.currentEditSummarySegmentId = segment.id;
    },
    async updateSegmentSummary(segment) {
      await reportService.updateProgramSegmentSummary(this.defaultProgram.id, {
        segment_id: segment.id,
        summary: segment.summary
      });
      this.currentEditSummarySegmentId = null;
      this.$toast(makeSuccessToast('Summary updated successfully.'));
    },
    async deleteSegmentSummary(segment) {
      segment.summary = null;
      await reportService.updateProgramSegmentSummary(this.defaultProgram.id, {
        segment_id: segment.id,
        summary: null
      });
      this.currentEditSummarySegmentId = null;
      this.$toast(makeSuccessToast('Summary deleted successfully.'));
    },
    getProgramGraphSummary(graph) {
      const programSuccess = this.reportSegments.find(segment => segment.name == 'Program Success Metrics');
      if (programSuccess) {
        return programSuccess.data[graph].summary;
      }
      return '';
    },
    showProgramGraphSummary(graph) {
      this.currentGraphEdit = graph;
    },
    async updateProgramGraphSummary(graph) {
      let summary = this.getProgramGraphSummary(graph);
      await reportService.updateProgramGraphSummary(this.defaultProgram.id, {
        name: graph,
        summary: summary
      });
      this.currentGraphEdit = null;
      this.$toast(makeSuccessToast('Summary updated successfully.'));
    },
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  },
  setup() {
    return {
      isDisplaySegment
    };
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>
<template>
  <b-tab
    title-item-class="flex-fill"
    lazy
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Survey Insights</span>
    </template>
    <b-tabs
      nav-class="d-flex justify-content-between"
      nav-item-class="flex-fill"
      content-class="tab-custom mt-2"
      nav-wrapper-class="mt-2 custom-wrapper"
      card
    >
      <b-tab
        v-if="isDisplaySegment(reportSegments, 'Initial survey - Mentor & Mentee')"
        title-item-class="flex-fill"
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Initial</span>
        </template>
        <b-card mt-2>
          <h4>Initial</h4>
        </b-card>

        <b-row>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <apexchart
                    type="radialBar"
                    :options="options"
                    :series="[70]"
                    height="150"
                  />
                  <p class="text-center">250 responses out of 300</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-card mt-2>
          <b-row class="d-flex">
            <b-col>
              <h4>Initial review summary</h4>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="outline-none"
                class="nav action-menu pr-0"
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                  dropleft
                >
                  <template 
                    #button-content
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />                
                  </template>

                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span>Add text</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
            </b-col>
          </b-row>
          <p>
            Summary generated by Open AI - ChatGPT can make mistakes. Check important info.
            Match capacity
            Summary generated by OpenAI ChatGPT. There may be mistakes, check important information
            Participant journey 
            Most of the participants are on track towards reaching their goals, there are some that are behind
            48 of 85
            89% of participants set goals during the program which is a great way to know you’re going on the
          </p>
        </b-card>
        
        <b-card>
          Engagement
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab
        v-if="isDisplaySegment(reportSegments, 'Close survey - Mentor & Mentee')"
        title-item-class="flex-fill"
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Initial</span>
        </template>
        <b-card mt-2>
          <h4>Initial</h4>
        </b-card>

        <b-row>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <apexchart
                    type="radialBar"
                    :options="options"
                    :series="[70]"
                    height="150"
                  />
                  <p class="text-center">250 responses out of 300</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-card mt-2>
          <b-row class="d-flex">
            <b-col>
              <h4>Initial review summary</h4>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="outline-none"
                class="nav action-menu pr-0"
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                  dropleft
                >
                  <template 
                    #button-content
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />                
                  </template>

                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span>Add text</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
            </b-col>
          </b-row>
          <p>
            Summary generated by Open AI - ChatGPT can make mistakes. Check important info.
            Match capacity
            Summary generated by OpenAI ChatGPT. There may be mistakes, check important information
            Participant journey 
            Most of the participants are on track towards reaching their goals, there are some that are behind
            48 of 85
            89% of participants set goals during the program which is a great way to know you’re going on the
          </p>
        </b-card>

        <b-card>
          Engagement
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab
        v-if="isDisplaySegment(reportSegments, 'Progress survey - Mentor & Mentee')"
        title-item-class="flex-fill"
        lazy
      >
        <template #title>
          <span class="font-weight-bold">Initial</span>
        </template>
        <b-card mt-2>
          <h4>Initial</h4>
        </b-card>

        <b-row>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <apexchart
                    type="radialBar"
                    :options="options"
                    :series="[70]"
                    height="150"
                  />
                  <p class="text-center">250 responses out of 300</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <b-row style="height:150px">
                <b-col>
                  <h3>250</h3>
                  <p>Delivered</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
                <b-col>
                  <h3>3</h3>
                  <p>Reminders</p>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-card mt-2>
          <b-row class="d-flex">
            <b-col>
              <h4>Initial review summary</h4>
            </b-col>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="outline-none"
                class="nav action-menu pr-0"
              >
                <b-nav-item-dropdown
                  class="btn-icon"
                  dropleft
                >
                  <template 
                    #button-content
                  >
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="20"
                    />                
                  </template>

                  <action-dropdown-item
                    link-class="d-flex align-items-center"
                  >
                    <feather-icon
                      size="16"
                      icon="PlusIcon"
                      class="mr-50"
                    />
                    <span>Add text</span>
                  </action-dropdown-item>
                </b-nav-item-dropdown>
              </b-button>
            </b-col>
          </b-row>
          <p>
            Summary generated by Open AI - ChatGPT can make mistakes. Check important info.
            Match capacity
            Summary generated by OpenAI ChatGPT. There may be mistakes, check important information
            Participant journey 
            Most of the participants are on track towards reaching their goals, there are some that are behind
            48 of 85
            89% of participants set goals during the program which is a great way to know you’re going on the
          </p>
        </b-card>

        <b-card>
          Engagement
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-row>
            <b-col sm="12">
              <h4 class="text-left font-weight-bold m-0 mb-1">
                Choose one answer that best applies to you.
              </h4>
            </b-col>

            <b-col sm="12">
              <b-row class="d-flex align-items-center mb-1">
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                />
                <b-col
                  sm="7"
                  class="text-center"
                />
                <b-col
                  sm="2"
                  class="text-center"
                >
                  Responses
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
              <b-row
                class="d-flex align-items-center mb-1"
              >
                <b-col
                  sm="3"
                  class="text-left text-truncate"
                >
                  Made contact but not scheduled a meeting
                </b-col>
                <b-col sm="6">
                  <b-progress
                    :value="79"
                    class="mr-1"
                    :class="
                      79 > 50 ?
                        'default-green-chart-color' :
                        'default-chart-color'
                    "
                  />
                </b-col>
                <b-col
                  sm="1"
                  class="text-left"
                >
                  {{ 79 }}%
                </b-col>
                <b-col
                  sm="2"
                  class="text-center"
                >
                  <b-badge
                    variant="light-success"
                    class="font-weight-normal"
                  >
                    {{ 11 }}
                  </b-badge>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import KeyResults from './KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle,
  BButton, BNavItemDropdown,
  BBadge
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import { isDisplaySegment } from "@/@aom-core/utils/utils";

export default {
  components: {
    BBadge,
    VueGoodTable,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation,
    BButton, BNavItemDropdown,
    ActionDropdownItem
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    surveyInsightsSegments() {
      return this.reportSegments.filter(segment => segment.section == 'survey_insights');
    }
  },
  data() {
    return {
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0,
      options: {
        chart: {
          type: 'radialBar',
        },
        grid: {
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                // fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
    };
  }, 
  methods: {
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  },
  setup() {
    return {
      isDisplaySegment
    };
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
.custom-wrapper {
  background-color: white!important;
}
</style>
<template>
  <b-tab
    title-item-class="flex-fill"
    lazy
  >
    <!-- title -->
    <template #title>
      <feather-icon
        icon="UsersIcon"
        size="18"
        class="mr-50"
      />
      <span class="font-weight-bold">Member engagement</span>
    </template>
    <b-card>
      <h4>Member engagement</h4>
    </b-card>
    <b-card class="mt-2">
      <h4>Participant onboarded</h4>
      <apexchart
        type="area"
        :options="areaOptions"
        :series="areaOptions.series"
        height="240px"
      />
    </b-card>
    <b-card class="mt-2">
      <h4>Participant journey</h4>
      <apexchart
        type="bar"
        :options="barOptions"
        :series="barOptions.series"
        height="240px"
      />
    </b-card>
    <div
      v-for="(segment, index) in engagementSegments" 
      :key="index"
    >
      <b-card class="mt-2" v-if="segment.name == 'Match progress segment'">
        <h4>Match progress</h4>
        <apexchart
          type="bar"
          :options="barOptions"
          :series="barOptions.series"
          height="240px"
        />
      </b-card>
      <b-card class="mt-2" v-if="segment.name == 'Sessions segment'">
        <b-row class="d-flex">
          <b-col>
            <h4>Sessions</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <apexchart
          type="bar"
          :options="barOptions"
          :series="barOptions.series"
          height="240px"
        />
      </b-card>
      <b-card class="mt-2" v-if="segment.name == 'Resources segment'">
        <b-row class="d-flex">
          <b-col>
            <h4>Resource engagement</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <apexchart
          type="bar"
          :options="barOptions"
          :series="barOptions.series"
          height="240px"
        />
      </b-card>
      <b-card class="mt-2" v-if="segment.name == 'Communications'">
        <b-row class="d-flex">
          <b-col>
            <h4>Communications</h4>
          </b-col>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="outline-none"
              class="nav action-menu pr-0"
            >
              <b-nav-item-dropdown
                class="btn-icon"
                dropleft
              >
                <template 
                  #button-content
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                  />                
                </template>

                <action-dropdown-item
                  link-class="d-flex align-items-center"
                >
                  <feather-icon
                    size="16"
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span>Add text</span>
                </action-dropdown-item>
              </b-nav-item-dropdown>
            </b-button>
          </b-col>
        </b-row>
        <apexchart
          type="bar"
          :options="barOptions"
          :series="barOptions.series"
          height="240px"
        />
      </b-card>
      <b-card class="mt-2" v-if="segment.name == 'Training segment'">
        <h4>Training</h4>
        <apexchart
          type="bar"
          :options="barOptions"
          :series="barOptions.series"
          height="240px"
        />
      </b-card>
      <b-card class="mt-2" v-if="segment.name == 'Survey response rate'">
        <h4>Survey response rate </h4>
        <b-row class="mt-2">
          <b-col class="text-center">
            <h4>Initial Survey: Mentee and Mentor</h4>
            <apexchart
              type="radialBar"
              :options="optionsParticipantOverview"
              :series="[70]"
            />
            <p>48 of 85</p>
          </b-col>
          <b-col class="text-center">
            <h4>Initial Survey: Mentee and Mentor</h4>
            <apexchart
              type="radialBar"
              :options="optionsParticipantOverview"
              :series="[70]"
            />
            <p>48 of 85</p>
          </b-col>
          <b-col class="text-center">
            <h4>Initial Survey: Mentee and Mentor</h4>
            <h4 class="mt-4">No data</h4>
            <p>Survey due to be sent out on 02/05/2025</p>
          </b-col>
          <b-col class="text-center">
            <h4>Initial Survey: Mentee and Mentor</h4>
            <h4 class="mt-4">No data</h4>
            <p>Survey due to be sent out on 02/05/2025</p>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import KeyResults from './KeyResults.vue';
import ProgramEvaluation from './ProgramEvaluation.vue';
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import {
  BFormGroup,
  BAvatar, 
  BRow,
  BCol, 
  BTabs,
  BTab,
  BCard,
  BProgress,
  BCardTitle,
  BButton, BNavItemDropdown
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';
import { reportService } from "@/services";
import { VueGoodTable } from "vue-good-table";
import { isDisplaySegment } from "@/@aom-core/utils/utils";

export default {
  components: {
    VueGoodTable,
    ActionDropdownItem,
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport,
    BTabs,
    BTab,
    BProgress,
    BCardTitle,
    KeyResults,
    ProgramEvaluation,
    BButton, BNavItemDropdown
  },
  props: {
    reportSegments: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
    engagementSegments() {
      return this.reportSegments.filter(segment => segment.section == 'member_engagement');
    }
  },
  data() {
    return {
      areaOptions: {
        colors: ["#9bcc65"],
        series: [{
            data: [31, 40, 28, 51, 42, 109, 100]
          }],
        chart: {
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2018-01-19T00:00:00.000Z", "2018-02-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
          labels: {
            format: 'MMM',
          }
        },
      },
      barOptions: {
        colors: ["#9bcc65"],
        series: [{
            data: [31, 40, 28, 51, 42, 109, 100]
          }],
        chart: {
          type: 'bar',
          zoom: {
            enabled: false
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'bottom', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'bottom',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
      },
      rows: [{
        full_name: "full_name",
        mentor: "mentor",
        mentees: "mentees",
      }],
      columns: [
        {
          label: "Question",
          field: "full_name",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentors",
          field: "mentor",
          tdClass: "text-nowrap",
          sortable: false
        },
        {
          label: "Mentees",
          field: "mentees",
          tdClass: "text-nowrap",
          sortable: false
        },
      ],
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0,
      options: {
        chart: {
          height: 20  ,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true
              }
            }
          }
        },

      },
      optionsParticipantOverview: {
        chart: {
          height: 400,
          type: 'radialBar',
        },
        grid: {
          padding: {
            top: -15,
            bottom: -15
          }
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: false,
              },
              value: {
                offsetY: 5,
                offsetX: 1,
                color: "#111",
                fontSize: "15px",
                show: true,
                formatter: function(val) {
                  return parseInt(val) + "% active";
                },
              },
            }
          }
        },

      }
    };
  }, 
  methods: {
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  },
  setup() {
    return {
      isDisplaySegment
    };
  }
};
</script>
<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
.tab-custom {
  .tab-pane {
    padding: 0;
  }
}
</style>